<template>
  <div class="print-container A4">
    <div class="invheader">
      <div class="invheader-upper">
        <div class="invheader-address-account">
          <br />
          <h1>شركه سوار الغربيه للتبريد</h1>
          <h3>افضل شركة تبريد</h3>
          <br />
          مطبوع بواسطة <br />
          {{ user.name }}
          <br />
          مدخل المعامله <br />
          {{ body.usersId ? body.usersId.name : '' }}
        </div>

        <div class="invheader-logo-container">
          <div class="invheader-logo">
            <img src="../../../../assets/logo2.png" style="max-height: 80px" />
          </div>
        </div>
      </div>

      <div class="invheader-lower">
        <div class="invheader-invoicedetails">
          <table cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <!-- <h1>
                    الصرف المخزنى
                  </h1> -->

                  <h1 v-if="body.inventoryType == 'inventory_adjustment'">
                    جرد مخزني
                  </h1>
                  <h1
                    v-if="body.inventoryType == 'inventory_damage_store_items'"
                  >
                    اتلاف مخزني
                  </h1>
                  <h1 v-if="body.inventoryType == 'inventory_direct_transfer'">
                    تحويل مخزني
                  </h1>
                  <h1 v-if="body.inventoryType == 'opening_balance'">
                    رصيد افتتاحي
                  </h1>
                  <h1 v-if="body.inventoryType == 'store_counting'">
                    صرف مخزني
                  </h1>
                  <h1 v-if="body.inventoryType == 'receiving_inventory'">
                    استلام مخزني
                  </h1>

                  #{{ body.code }}
                </th>
              </tr>
              <tr>
                <th>
                  <h4>
                    {{ $durationFormatFull(body.date) }}
                  </h4>
                </th>
                <!-- <td><h2>من تاريخ</h2></td> -->
              </tr>
              <tr class="invheader-invoicedetails-balance">
                <!-- <th>
                    <div><h3>1 1 2022</h3></div>
                  </th>
                  <td>
                    <div><h3>1 1 2021</h3></div>
                  </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="invbody">
      <table cellspacing="0" class="invbody-items">
        <thead style=" border: #000 solid 6px;color: #000;">
          <tr>
            <th class="last" style=" border: #000 solid 1px;color: #000;">
              <div class="linetotal">#</div>
            </th>
            <th class="last" style=" border: #000 solid 1px;color: #000;">
              <div class="linetotal">المنتج</div>
            </th>
            <th style=" border: #000 solid 1px;color: #000;">
              <div class="quantity">الكمية</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="x of body.storeItemsList" :key="x.id">
            <td class="last" style=" border: #000 solid 1px;color: #000;">
              <div class="linetotal">{{ x.storeItemsId.code }}</div>
            </td>

            <td class="last" style=" border: #000 solid 1px;color: #000;">
              <div class="linetotal">{{ x.storeItemsId.name }}</div>
            </td>
            <td style=" border: #000 solid 1px;color: #000;">
              <div class="quantity">{{ x.quantity }}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="invbody-summary">
        <div class="invsummary-invoicedetails">
          <table cellspacing="0">
            <tbody>
              <tr>
                <th style="width: 150px">
                  <strong>مستودع</strong>
                </th>
                <td style="width: 120px">
                  {{ body.warehouseId ? body.warehouseId.name : '' }}
                </td>
              </tr>
              <tr v-if="body.note">
                <th style="width: 150px">
                  <strong>ملاحظات</strong>
                </th>
                <td style="width: 120px">
                  {{ body.note }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="row text-center">
      <div class="col-md-4 col-xs-4 col-sm-4">
        <b>
          امين المستودع
        </b>
      </div>
      <div class="col-md-4 col-xs-4 col-sm-4">
        <b>
          المستلم
        </b>
      </div>
      <div class="col-md-4 col-xs-4 col-sm-4">
        <b>
          الاعتماد
        </b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    const user = JSON.parse(localStorage.admin);

    return {
      user,
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        date: new Date(),
        storeItemsList: [],
        taxesId: null,
        warehouseId: null,
        note: null,
        usersId: null,
        // price: 0,
        // total: 0,
      },
      productsAdd: {
        storeItemsId: null,
        quantity: 1,
        // price: 1,
      },
      id: null,
      isInvalid: false,
      disabledAdd: false,
      displayProductsAdd: false,
      storeItemsList: [],
      warehouseList: [],
      taxesList: [],
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  created() {
    // this.$http.get(`warehouse`).then((res) => {
    //   for (const item of res.data) {
    //   }
    //   this.warehouseList = res.data.filter((itm) =>
    //     itm.users.find((u) => u.id == user.id),
    //   );
    // });َ

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`inventoryOpeningBalance/${this.id}`).then(
        async (res) => {
          this.body = await res.data;
          // this.body.warehouseId = await res.data.warehouseId;
          this.body.storeItemsList = await JSON.parse(res.data.storeItemsList);
          await setTimeout(() => {
            window.print();
            window.close();
          }, 1000);
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style scoped>
.invbody-items tr > th,
.invbody-items tr > td {
  border: 1px solid rgb(0, 0, 0) !important;
  border-top-color: rgb(15, 15, 15) !important;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.print-container {
  background-color: #fff;
  clear: both;
  /*Do something here with A4*/
  padding: 0px 40px 0px 40px;
  z-index: 0;
  /* height: 100vh; */
}

.print-title {
  font-family: 'Cairo', sans-serif !important;
  position: absolute;
  top: 0;
  margin-left: 0.5in;
  border: solid 1px #f1f1f1;
  padding: 3px 5px;
}

.clearb {
  clear: both;
}

.invheader {
  min-height: 230px;
  position: relative;
}

.invheader-upper {
  z-index: 10;
  position: relative;
  min-height: 90px;
}

/* .invheader-lower {
    clear: both;
    margin-top: 20px;
    min-height: 100px;
} */

.invheader-address-account {
  float: right;
  /* width: 300px; */
  margin-bottom: 20px;
}

.invheader-logo {
  float: left;
}

.invheader-logo-container {
  width: 250px;
  height: 90px;
  float: left;
}

.invheader-address-client {
  float: right;
  width: 300px;
  padding-bottom: 20px;
}

.invheader-invoicedetails {
  float: left;
  /* width: 300px; */
  padding-bottom: 20px;
  padding-right: 20px;
  page-break-inside: avoid;
}

.invheader-invoicedetails table {
  width: 100%;
}

.invsummary-invoicedetails {
  float: right;
  width: 300px;
  padding-bottom: 20px;
  padding-left: 20px;
  page-break-inside: avoid;
  text-align: right;
}

.invbody {
  clear: both;
  position: relative;
  overflow: auto;
}

.invheader,
.invheader th,
.invbody,
.invbody .description,
.invstub th,
.invheader-invoicedetails th,
.attachment-header th {
  font-family: 'Cairo', sans-serif !important;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
}

.invheader-logo {
  text-align: center;
  font-size: 11px;
  line-height: 12px;
}

.attachment-image img {
  max-height: 9in;
  max-width: 100%;
}

.invheader-invoicedetails table th {
  width: 50%;
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

.invheader-invoicedetails table td,
.attachment-header td {
  text-align: right;
  font-family: 'Cairo', sans-serif !important;
  font-size: 12px;
  line-height: 18px;
}

.invheader-invoicedetails th,
.invheader-invoicedetails td {
  padding: 0px 5px;
}

.invheader-invoicedetails-balance th,
.invheader-invoicedetails-balance td,
.attachment-header th,
.attachment-header td {
  /*  background-color: #e5e5e5;*/
  /*  border-top: solid 1px #c7c7c7;*/
  /*  border-bottom: solid 1px #c7c7c7;*/
  padding: 0px;
  font-weight: bold;
}

.attachment-header td {
  font-weight: normal;
}

.attachment-header th,
.attachment-header td {
  vertical-align: top;
}

.invheader-invoicedetails .invheader-invoicedetails-balance th div,
.attachment-header th div {
  /*  border-left: solid 1px #f1f1f1;*/
  /*  border-top: solid 1px #f1f1f1;*/
  padding: 3px 5px;
}

.invheader-invoicedetails .invheader-invoicedetails-balance td div,
.attachment-header td div {
  /*  border-top: solid 1px #f1f1f1;*/
  padding: 3px 5px;
}

/*
     * 		TABLE
     */

.invbody-items.worksheet-table {
  width: 32%;
}

.invbody-items {
  width: 100%;
  margin-bottom: 24px;
}

.invbody-items td,
.invbody-expenses td,
.invbody-timeentries td {
  vertical-align: top;
}

.invbody-items thead,
.invbody-expenses thead,
.invbody-timeentries thead {
  font-weight: bold;
  page-break-after: avoid;
}

.invbody-items tbody,
.invbody-expenses tbody,
.invbody-timeentries tbody {
  page-break-before: avoid;
  vertical-align: top;
}

.invbody-items td,
.invbody-expenses td,
.invbody-timeentries td {
  padding: 3px 0px 3px 2px;
  /*  border-bottom: solid 1px #e5e5e5;*/
}

.invbody-items th,
.invbody-expenses th,
.invbody-timeentries th {
  /*  background-color: #e3e3e3;*/
  line-height: normal;
  /* border-top: solid 1px #c7c7c7; */
  border-bottom: solid 1px #c7c7c7;
  vertical-align: top;
}

.invbody-items th div,
.invbody-expenses th div,
.invbody-timeentries th div {
  padding: 5px 0;
}

/*
     * 		ITEMS TABLE
     */

.invbody-items thead .item {
  /* border-left: solid 1px #f1f1f1; */
  /* border-top: solid 1px #f1f1f1; */
  padding-right: 5px;
  text-align: right;
}

.invbody-items tbody .item {
  padding-right: 5px;
  text-align: right;
}

.invbody-items thead .description {
  /* border-top: solid 1px #f1f1f1; */
  text-align: right;
}

.invbody-items thead .unitcost {
  /* border-top: solid 1px #f1f1f1; */
  text-align: right;
}

.invbody-items tbody .unitcost {
  text-align: right;
}

.invbody-items thead .quantity {
  /* border-top: solid 1px #f1f1f1; */
  text-align: right;
  padding-right: 5px;
}

.invbody-items tbody .quantity {
  text-align: right;
  padding-right: 5px;
}

.invbody-items thead .linetotal {
  /* border-top: solid 1px #f1f1f1; */
  text-align: right;
  padding-right: 5px;
}

.invbody-items tbody .linetotal {
  text-align: right;
  padding-right: 5px;
}

/*
     * 		EXPENSE TABLE
     */

.invbody-expenses thead .description {
  /*  border-left: solid 1px #f1f1f1;*/
  /*  border-top: solid 1px #f1f1f1;*/
  padding-left: 5px;
  text-align: left;
}

.invbody-expenses tbody .description {
  padding-left: 5px;
  text-align: left;
}

.invbody-expenses thead .unitcost {
  /*  border-top: solid 1px #f1f1f1;*/
  text-align: right;
}

.invbody-expenses tbody .unitcost {
  text-align: right;
}

.invbody-expenses thead .quantity {
  /*  border-top: solid 1px #f1f1f1;*/
  text-align: right;
}

.invbody-expenses tbody .quantity {
  text-align: right;
}

.invbody-expenses thead .linetotal {
  /*  border-top: solid 1px #f1f1f1;*/
  text-align: right;
  padding-right: 5px;
}

.invbody-expenses tbody .linetotal {
  text-align: right;
  padding-right: 5px;
}

/*
     * 		TIME ENTRIES TABLE
     */

.invbody-timeentries thead .notes {
  /*  border-left: solid 1px #f1f1f1;*/
  /*  border-top: solid 1px #f1f1f1;*/
  padding-left: 5px;
  text-align: left;
}

.invbody-timeentries tbody .notes {
  padding-left: 5px;
  text-align: left;
}

.invbody-timeentries thead .rate {
  /*  border-top: solid 1px #f1f1f1;*/
  text-align: right;
}

.invbody-timeentries tbody .rate {
  text-align: right;
}

.invbody-timeentries thead .hours {
  /*  border-top: solid 1px #f1f1f1;*/
  text-align: right;
}

.invbody-timeentries tbody .hours {
  text-align: right;
}

.invbody-timeentries thead .linetotal {
  /*  border-top: solid 1px #f1f1f1;*/
  text-align: right;
  padding-right: 5px;
}

.invbody-timeentries tbody .linetotal {
  text-align: right;
  padding-right: 5px;
}

/*
     * 		SUMMARY TABLE
     */

.invbody-summary {
  /* page-break-before: always; */
  /* page-break-inside: avoid; */
  /* min-height: 126px; */
  /* clear: both; */
  width: 100%;
  /*  border-top: solid 3px #e3e3e3;*/
  margin-bottom: 18px;
  margin-top: 18px;
}

.invbody-summary th {
  font-weight: normal;
  text-align: left;
}

.invbody-summary td {
  text-align: right;
}

.invbody-summary th,
.invbody-summary td {
  padding: 0px 5px;
}

.invbody-summary .border {
  /*  border-top: solid 1px #e5e5e5;*/
  padding-top: 3px;
}

.invbody-summary-paid td,
.invbody-summary-paid th {
  padding-bottom: 3px;
}

.invbody-summary-subtotal td,
.invbody-summary-subtotal th {
  padding-top: 3px;
}

.invbody-summary-discount td,
.invbody-summary-discount th {
  padding-top: 3px;
}

.invbody-summary-total td,
.invbody-summary-total th {
  /*  background-color: #e5e5e5;*/
  /*  border-top: solid 1px #c7c7c7;*/
  /*  border-bottom: solid 1px #c7c7c7;*/
  padding: 0px;
}
.invbody-summary .invbody-summary-total th div {
  /*  border-left: solid 1px #f1f1f1;*/
  /*  border-top: solid 1px #f1f1f1;*/
  padding: 3px 5px;
}

.invbody-summary .invbody-summary-total td div {
  /*  border-top: solid 1px #f1f1f1;*/
  padding: 3px 5px;
}

.A4 {
  background: white;
  width: 21cm;
  /* height: 29.7cm; */
  display: block;
  margin: 0 auto;
  padding: 10px 25px;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  /* overflow-y: scroll; */
  box-sizing: border-box;
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }

  /* size : A4 portrait; */

  body {
    margin: 0;
    padding: 0;
  }

  .A4 {
    box-shadow: none;
    margin: 0;
    width: auto;
    height: auto;
  }

  .invbody-items tr > th,
  .invbody-items tr > td {
    border: 1px solid rgb(0, 0, 0) !important;
    border-top-color: rgb(15, 15, 15) !important;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
}
</style>
